export default [
  {
    path: "solution/detail/green/energy",
    name: "SolutionGreenEnergy",
    component: () => import(/* webpackChunkName: "SolutionGreenEnergy" */ "../../views/solution/green-energy.vue"),
    meta: {
      title: "绿色能源综合解决方案",
      main: "solution",
      needLogin: false,
    },
  },
  {
    path: "solution/detail/factory/energy",
    name: "SolutionFactoryEnergy",
    component: () => import(/* webpackChunkName: "SolutionFactoryEnergy" */ "../../views/solution/factory-energy.vue"),
    meta: {
      title: "工厂能源托管解决方案",
      main: "solution",
      needLogin: false,
    },
  },
]
