export default [
  {
    path: "news/home",
    name: "MainNewsHome",
    component: () => import(/* webpackChunkName: "MainNewsHome" */ "@/views/news/home/index.vue"),
    meta: {
      main: "home",
      needLogin: false,
      title: "能源资讯",
    },
  },
  {
    path: "news/search",
    name: "MainNewsSearch",
    component: () => import(/* webpackChunkName: "MainNewsSearch" */ "@/views/news/search/index.vue"),
    meta: {
      main: "home",
      needLogin: false,
      title: "能源资讯",
    },
  },
  {
    path: "news/list",
    name: "MainNewsList",
    component: () => import(/* webpackChunkName: "MainNewsList" */ "@/views/news/list/index.vue"),
    meta: {
      main: "home",
      needLogin: false,
      title: "新闻热榜",
    },
  },
  {
    path: "news/policy",
    name: "NewsPolicy",
    component: () => import(/* webpackChunkName: "NewsPolicy" */ "@/views/news/policy/index.vue"),
    meta: {
      main: "home",
      needLogin: false,
      title: "政策法规",
    },
  },
  {
    path: "news/policy/detail",
    name: "NewsPolicyDetail",
    component: () => import(/* webpackChunkName: "NewsPolicyDetail" */ "@/views/news/policy/detail/index.vue"),
    meta: {
      main: "home",
      needLogin: false,
      title: "政策法规详情",
    },
  },
  {
    path: "news/bidding",
    name: "NewsBidding",
    component: () => import(/* webpackChunkName: "NewsBidding" */ "@/views/news/bidding/index.vue"),
    meta: {
      main: "home",
      needLogin: false,
      title: "招投标信息",
    },
  },
  {
    path: "news/bidding/detail",
    name: "BiddingDetail",
    component: () => import(/* webpackChunkName: "BiddingDetail" */ "@/views/news/bidding/detail/index.vue"),
    meta: {
      main: "home",
      needLogin: false,
      title: "招投标详情",
    },
  },
  {
    path: "news/ceia",
    name: "NewsCEIA",
    component: () => import(/* webpackChunkName: "NewsCEIA" */ "@/views/news/ceia/index.vue"),
    meta: {
      main: "home",
      needLogin: false,
      title: "BSEOS精选文章",
    },
  },
  {
    path: "news/detail",
    name: "MainNewsDetail",
    component: () => import(/* webpackChunkName: "MainNewsDetail" */ "@/views/news/detail/index.vue"),
    meta: {
      main: "home",
      needLogin: false,
      title: "新闻详情",
    },
  },
  {
    path: "news/company",
    name: "NewsCompany",
    component: () => import(/* webpackChunkName: "NewsCompany" */ "@/views/news/company/index.vue"),
    meta: {
      main: "home",
      needLogin: false,
      title: "公司新闻",
    },
  },
]
